import { Link } from 'gatsby'
import * as React from 'react'
// import { Link } from 'gatsby'
import {footer, footerLeft, footerRight} from '../components/layout.module.css'



const Footer = () => {

  return (
    <div className={footer}>
      <div className={footerLeft}>© Lauren Sadow, 2021</div>
      <div className={footerRight}><Link to="/suggest">Suggestion box</Link></div>
    </div>
  )
}

export default Footer