import * as React from 'react'
import { Link } from 'gatsby'
import {
  navbar,
  mobileMenuContainer, mobileDropdownLeft,
  mobileMenuIcon, mobileDropdownContent,
  menuContainer, menuLink
} from './layout.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'


const Menu = () => {
  return (
<nav className={navbar}>
        <div className={menuContainer}>
        <Link to="/" className={menuLink}>
          HOME
        </Link>        
        <Link to="/by-topic" className={menuLink}>
          BY TOPIC
        </Link>
        <Link to="/part-of-speech" className={menuLink}>
            BY PART OF SPEECH
          </Link>
        <Link to="/entry-index" className={menuLink}>
            ENTRY INDEX
        </Link>
        <Link to="/about" className={menuLink}>
            ABOUT
        </Link>
        <Link to="/help" className={menuLink}>
            HELP
        </Link>
        <Link to="/search" className={menuLink}>
           SEARCH
        </Link>
        </div>

        <div className={mobileMenuContainer}>
        <Link to="/" className={mobileDropdownLeft}>
            <FontAwesomeIcon className={mobileMenuIcon} icon={faBars} />
        <div className={mobileDropdownContent}>
          <Link to="/">HOME</Link>
          <Link to="/by-topic">BY TOPIC</Link>
          <Link to="/part-of-speech">BY PART OF SPEECH</Link>
          <Link to="/entry-index">FULL INDEX</Link>
              <Link to="/about-me">ABOUT LAUREN</Link>
              <Link to="/about-ausdict">ABOUT THE AUSDICT</Link>
          <Link to="/help">HELP</Link>
          <Link to="/search">SEARCH</Link>
        </div>
        </Link>
        <Link to="/search" className={mobileDropdownLeft}>
            <FontAwesomeIcon className={mobileMenuIcon} icon={faSearch} />
        </Link>
        </div>

      </nav>

)
}

export default Menu